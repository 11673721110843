/**
 * This file should get overwritten if Method 1 is used. If Method 2 is chosen,
 * remove this comment and start adding your styles to this file.
 */

 /*-----------------------Variables--------------------*/

 $purple: #853894;
 $white: #ffffff;
 $white-trans: rgba(255,255,255,0.85);
 $green: #C4D600;
 $blue: #4cb4e7;
 $darkGrey: #424242;
 $lllgrey: #e4e4e4;
 $llgrey-trans: rgba(103, 103, 103, 0.2);
 $lgrey: #d8d8d8;
 $grey: #9EA1A2;
 $dgrey: #6f6f6f;

 $black: #000;

 $bebas: "bebas-neue",sans-serif;
 $ubuntu: "ubuntu",sans-serif;

/*-----------------------Basic Styles--------------------*/

html, body{
	font-size: 18px;
    font-family: $ubuntu;
    overflow-x: hidden;
    color: $darkGrey;
}

h1{
	font-size: 2.5em;
    font-family: $bebas;
}

h2{
	font-size: 46px;
    font-family: $bebas;
}

h3{
	font-size: 1.5em;
}

p{
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 20px;
}

a{ 
    color: $blue;
}

a:hover{
    color: $purple;
}

li{
    padding-bottom: 10px;
}

.mt10{
    margin-top: 10px;
}

.mr10{
    margin-right: 10px;
}

.mb10{
    margin-bottom: 10px;
}

.ml10{
    margin-left: 10px;
}

.mt15{
    margin-top: 15px;
}

.mr15{
    margin-right: 15px;
}

.mb15{
    margin-bottom: 15px;
}

.ml15{
    margin-left: 15px;
}

.mt20{
    margin-top: 20px;
}

.mr20{
    margin-right: 20px;
}

.mb20{
    margin-bottom: 20px;
}

.ml20{
    margin-left: 20px;
}

.mt40{
    margin-top: 40px;
}

.mb40{
    margin-bottom: 40px;
}

.pt10{
    padding-top: 10px;
}

.pr10{
    padding-right: 10px;
}

.pb10{
    padding-bottom: 10px;
}

.pl10{
    padding-left: 10px;
}

.pt15{
    padding-top: 15px;
}

.pr15{
    padding-right: 15px;
}

.pb15{
    padding-bottom: 15px;
}

.pl15{
    padding-left: 15px;
}

.pt20{
    padding-top: 20px;
}

.pr20{
    padding-right: 20px;
}

.pb20{
    padding-bottom: 20px;
}

.pl20{
    padding-left: 20px;
}

.pt40{
    padding-top: 40px;
}

.pr40{
    padding-right: 40px;
}

.pb40{
    padding-bottom: 40px;
}

.pl40{
    padding-left: 40px;
}

.pb80{
    padding-bottom: 80px;
}

.green__text{
    color: $green;
}

.purple__text{
    color: $purple;
}

.blue__text{
    color: $blue;
}

.page-header{
    display: none;
}

.row{
	margin:0px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
	margin: 0px;
	padding: 0px;
}

/*-----------------------Header--------------------*/
#navbar{
	position: fixed;
	width: 100%;
	background: #f7f7f7;
	border: 0px;
	border-radius: 0px;
	top: 0px;
	z-index: 4;
    padding-top: 10px;
}


.logo_image #Fill-1_1_{
    fill:#f7f7f7;
}

.navbar-header{
	width: 100%;
    float: none;
}

.logged-in #navbar{
	top: 65px;
}

.logo_text{
	padding: 17px 0px;
}

.logo_image{
	position: absolute;
    left: 50%;
    margin-left: -75px;
}

.menu_toggle{
	float: right;
    font-size: 20px;
    padding: 17px 0px;
    font-weight: bold;
    cursor: pointer;
    color: $purple;
    text-transform: uppercase;
}

/*-----------------------Navigation--------------------*/

.nav_menu{
	display: none;
	position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1000;
}

.nav_background{
	height: 100vh;
    width: 100%;
    background: #853894;
    opacity: 0.95;
    position: absolute;
}

.nav_close{
	position: absolute;
	color: $white;
	top: 10px;
	right: 200px;
	font-size: 32px;
	z-index: 1;
	cursor: pointer;
}

.nav_hex{
	position: absolute;
    top: -80px;
    right: 0px;
    z-index: 1;
    opacity: 0.5;

    /*position: absolute;
    top: 250px;
    right: 500px;
    z-index: 1;
    opacity: 0.4;
    transform: rotate(-59deg);*/
}

.nav_hex path{
	display: none;
}

.nav_column{
	display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.nav_menu .search_block{
	font-size: 2rem;
}


.search_block h2{
	color: $white;
    font-size: 1em;
    font-family: $ubuntu;
    font-weight: 100;
}

.nav_menu input{
	height: 60px;
    border-radius: 0px;
    font-size: 32px;
    outline: none !important;
    box-shadow: none !important;
    border: 0px !important;
}

.nav_menu button{
	height: 60px;
    border: 0px;
    border-radius: 0px;
    font-size: 24px;
    color: $purple;
    background: $white;
}

.nav_menu nav{
	
}

.nav_menu nav li{
    padding-bottom: 0px;
}

.nav_menu nav a{
	color: $white;
    margin-left: 80px;
    border-left: 3px solid $white;
    padding-left: 80px;
    font-size: 3rem;
    position: relative;
    z-index: 2;
}



.nav_menu nav a:hover,
.nav_menu nav a:active,
.nav_menu nav a:visited{
	background: transparent;
}

.nav_menu nav a:hover{
	border-left: 3px solid $blue;
}

.nav_menu nav a.active{
    color: $blue;
}


/*-----------------------Main Container--------------------*/

.not-front .main-container{
    min-height: calc(100vh - 674px);
    margin-top: 674px;
    position: relative;
    z-index: 2;
    background: $white;
}

/*-----------------------Hompepage--------------------*/

.background_image{
	width: 100%;
	height: 100vh;
    background-color: $purple;
    overflow: hidden;
    position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.video__image video{
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translateX(50%) translateY(50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
}

.overlay{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    z-index: 1;
    position: relative;
}

.background_image .container{
	position: relative;
	height: 100vh;
}

.cta{
	color: $white;
    position: absolute;
    bottom: 40%;
}

.cta h1{
	font-size: 64px;
    margin-bottom: 30px;
}

.cta a{
	display: inline-block;
    color: $blue;
    font-size: 24px;
    border: 0px solid $white;
    padding: 12px 24px;
    background-color: $white-trans;
}

ul#menu{
	position: fixed;
    right: 20px;
    list-style-type: none;
    z-index: 3;
    margin: -35px 0px 0px;
    padding: 0px;
    top: 50%;
}

ul#menu a{
	display: block;
    margin-bottom: 10px;
}

ul#menu svg path{
	fill: $white-trans;
    stroke-width: 0px;
}

ul#menu .active svg path{
	fill: $white;
}

.section{
	z-index: 2;
}

/*-----------------------Featured Header--------------------*/

.featured_top{
    margin-top: 74px;
    position: fixed;
    top: 0px;
    width: 100%;
}

.featured_top-image{
    float: left;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 600px;
}

.featured_top-image-full{
    width: 100% !important;
    position: relative;
    float: none;
    margin-top: 74px;
    position: fixed;
    top: 0px;
}

.work__header-title{
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0 20px;
    font-size: 64px;
    margin-top: -35px;
}

.work__header-title h2{
    font-family: $ubuntu;
    color: $white;
    text-align: center;
    font-size: 36px;
    font-weight: 100;
}

.featured_top-image-full h1{
    max-width: 1140px;
    margin: 0 auto;
    font-size: 52px;
    color: #fff;
    text-align: center;
}

.featured_top-text{
	position: relative;
	padding: 80px 20px;
    background: $darkGrey;
    color: $white;
    font-size: 1.2rem;
    float: left;
}

.featured_top-body{
	position: absolute;
	bottom: 0px;
    padding: 60px 40px;
}

.featured_top-body h2{
    font-family: $ubuntu;
    font-size: 42px;
    font-weight: 100;
}

.featured_top-body p{
    font-size: 20px;
    margin-bottom: 40px;
}

.featured-social a{
    display: block;
    float: left;
    margin-right: 10px;
}

.featured__services{
    position: absolute;
    top: 0px;
    padding:60px 40px;
    font-size: 18px;
    color: #aaa;
}

.featured__services ul{
    padding: 0px;
    list-style: none;
}

/*-----------------------Main Team--------------------*/

.team__main{
    float: left;
    width: calc(20% + 1.8px);
    width: 20%;
    border: 2px solid $white;
    margin: -1px;
    border: 0px;
    margin: 0px; 
    position: relative;
    overflow: hidden;
}

.team__main-img img{
    width: 100%;
    height: auto;
}

.team__main-text{
    position: absolute;
    top: 100%;
    transition: all 0.3s ease;
}

.team__main:hover .team__main-text{
    top: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(133, 55, 148, 0.8);
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: $white;
}

.team__main-text h2{
    font-family: $ubuntu;
    font-weight: 500;
    color: #fff;
    font-size: 34px;
}

.team__main-text p{
    line-height: 24px;
}



/*-----------------------Individual Team Header--------------------*/

.featured_top-text-team,
.featured_top-text-team a{
    background: $purple;
    color: $white;
    font-family: $ubuntu;
    font-size: 24px;
}

.featured_top-text-team h2{
    text-transform: uppercase;
    font-family: $bebas;
    font-size: 48px;
}

.featured__team-hex{
    position: absolute;
    top: 126px;
    transform: rotate(60deg) scale(1, -1);
    right: -109px;
}

.featured__team-hex path{
    display: none;
}


/*-----------------------Individual Team Related Content--------------------*/
.view-id-team_related_content{
    margin-bottom: 40px;
}

.related__work{
    position: relative;
    background: $lllgrey;
    margin-bottom: 30px;

}

.related__work a{
    color: $darkGrey;
}

.related__work-img{
    overflow: hidden;
    height: 300px;
}

.related__work-img img{
    width: 100%;
    height: auto;
}

.related__work-img,
.related__work-text{
    float: left;
    width: 50%;
    min-height: 200px;
    
}

.related__work-title,
.related__work-title h2{
    font-family: ubuntu;
    font-size: 36px;
    font-weight: 100;
}

.related__work-title{
    position: absolute;
    bottom: 10px;
    left: 50%;
    padding: 0px 20px;
}

.related__work-title h3{
    font-weight: bold;
    font-size: 20px;
}

.related__work-title,
.related__work-title h2{
    font-family: ubuntu;
    font-size: 36px;
    font-weight: 100;
}

.related__work-type{
    position: absolute;
    top: 10px;
    left: 50%;
    padding: 0px 20px;
    color: $white;
}


.related__blog{
    background: $darkGrey;
}

.related__blog a{
    color: $blue;
}

/*-----------------------Work Main Page--------------------*/
.page-work .masonry{
    width: 100% !important;
}

.page-work .masonry-item{
    margin: 0px;
    padding: 0px;
    border: 0px;
    width: 33.33%;
}

.work-main{
    position: relative;
    width: 33.33%;
    float: left;
    overflow: hidden;
}

.work-text,
.work__random-text{
    position: absolute;
    top: 100%;
    height: 100%;
    width: 100%;
    background-color: rgba(133, 55, 148, 0.9);
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $white;
    transition: all 0.3s ease;
}

.work-text div{
    width: 100%;
}

.work-text h2,
.work-text p,
.work__random-text h2,
.work__random-text p{
    width: 100%;
    text-align: center;
}

.work-text h2,
.work__random-text h2{
    font-family: ubuntu;
    font-weight: 100;
    padding: 0 60px;
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 32px;
    line-height: 38px;
}

.work-text p,
.work__random-text p{
    font-weight: bold;
    font-size: 20px;
}

.work-main:hover .work-text{
    top: 0%;
    
}

/*-----------------------Individual Work Header--------------------*/

.work__header-details{
    background: $blue;
    color: $white;
    font-family: $ubuntu;
    font-size: 16px;
    padding: 30px 0px;
    clear: both;
    position: relative;
    z-index: 1;
}

.work__header-details h4{
    font-family: $bebas;
    font-size: 24px;
    color: $white;
    border-top: 1px solid $white;
    margin-right: 20px;
    padding: 10px 0 0;
    letter-spacing: 1px;
}

.work__header-details p{
    margin-right: 20px;
}

.work__header-details ul{
    padding: 0px;
}

.work__header-details li{
    list-style: none;
}

.work__header-details a{
    color: $white;

}

/*-----------------------Individual Work--------------------*/

.view-work-individual-page h2{
    font-family: $bebas;
    font-size: 48px;
    letter-spacing: 1px;
    line-height: 48px;
    font-weight: bold;
}

.node-type-work .col-sm-* img{
    height: auto;
    width: 100%;
}

.view-work-individual-page p{

}

.work__small{
    font-size: 18px;
    padding: 0 20px 20px 0px;
}

.work__challenge{
    padding: 40px 0;
}

.work__process{
    padding: 40px 0;
}

.work__tech{
    padding: 0 0 20px 0;
}

.node-type-work h3{
    font-family: $ubuntu;
    font-size: 34px;
    border-top: 1px solid #ccc;
    line-height: 48px;
    padding-top: 10px;
    margin-top: 45px;
    font-weight: 100;
}


/*-----------------------Individual Blog--------------------*/

.view-individual-blog-block{
    padding: 40px 0px;
    background: #fff;
    width: 100%;
    position: relative;
}

.pull-quote{
    font-family: $ubuntu;
    font-size: 40px;
    color: $purple;
    margin: 20px 0px 30px;
    text-align: left;
    font-weight: 100;
    line-height: 52px;
}

.blog__body{
    margin-top: 40px;
}

.blog__body p:first-child:first-letter{
    float: left;
    display: block;
    padding: 6px 8px 0px 0px;
    font-size: 84px;
    color:$grey;
    font-family: $bebas;
}

/*-----------------------Main Blog--------------------*/

.view-id-main_blog{
    margin-top: 20px;
}

.view-main-blog .views-row{
	width: 50%;
    float: left;
    background: none;
    border: 0px;
    margin: 0px;
    padding: 0px;
}

.blog_listing-image{
	background: $blue;
}

.blog__overlay{
    height: 100%;
    width: 0%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 2;
}

.blog_listing:hover .blog__overlay{
    width: 100%;
    transition: all ease 0.3s;
}

.blog_listing:hover{

}

.blog_listing h2{
    font-size: 24px;
    font-family: ubuntu;
    font-weight: 100;
}

.blog_listing{
    overflow: hidden;
}



.blog_listing-text{
	font-size: .65rem;
	padding: 10px;
    position: relative;
    z-index: 3;
}

.blog_listing-text a:hover{
    color: $white;
    text-decoration: none;
    transition: all ease 0.3s;
}

.blog__overlay_tile{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    overflow: hidden;
}

.tile{
    height: 57px;
    width: 57px;
    float: left;
    background: rgba(0,0,0,0.6);
    position: relative;
    z-index: 1;
    display: none;
}

.tile:nth-child(odd){
    
}

.tile:nth-child(even){

}

.pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none;
    clear: both;
    padding-top: 20px;
    /* border-top: 1px solid #ccc; */
}

.view-main-blog .views-row-3 .col-sm-6,
.view-main-blog .views-row-4 .col-sm-6,
.view-main-blog .views-row-7 .col-sm-6,
.view-main-blog .views-row-8 .col-sm-6,
.view-main-blog .views-row-11 .col-sm-6,
.view-main-blog .views-row-12 .col-sm-6,
.view-main-blog .views-row-15 .col-sm-6,
.view-main-blog .views-row-16 .col-sm-6,
.view-main-blog .views-row-19 .col-sm-6,
.view-main-blog .views-row-20 .col-sm-6{
	float: right;
}

/*-----------------------Clients--------------------*/

.client__main{
    float: left;
    width: 20%;
    border-bottom: 2px solid $lgrey;
    border-right: 2px solid $lgrey;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/*-----------------------Main Services Page--------------------*/

.view-id-main_services_page .view-header{
    margin: 40px 0px;
}

.view-id-main_services_page .view-header h2{
    font-family: $bebas;
}

.main__service{
    background: #eee;
}

.main__service a{
    color: $darkGrey;
    font-family: $bebas;
}

.views-row-even .main__service-text{
    float: right;
}

.main__service-text{
    padding: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 355px;
}

.main__service-text h1{
    margin-top: 0px;
    font-family: ubuntu;
    font-size: 36px;
    font-weight: 100;
}

.main__service-text p{
    font-family: $ubuntu;
}

/*-----------------------Services Individual Page--------------------*/

.services-body{
    padding: 50px 0px 20px;
}

.services-sub ul{
    padding: 0px;
}


.services-sub li{
    width: 47%;
    display: block;
    float: left;
    margin: 20px 0px 40px;
}

.services-sub li:nth-child(even){
    margin-left: 3%;
}

.services-sub li:nth-child(odd){
    margin-right: 3%;
    clear: left;
}

.services-body h3{
    display: block;
    font-family: $bebas;
    font-size: 48px;
    letter-spacing: 1px;
    border-top: 1px solid #000;
    line-height: 48px;
    padding-top: 15px;
    font-weight: bold;
    padding-top: 0px;
    border: 0px;
}

.services-sub strong{
    display: block;
    font-family: $ubuntu;
    color: $purple;
    font-size: 34px;
    border-top: 1px solid #ccc;
    line-height: 48px;
    padding-top: 20px;
    font-weight: 100;
}




/*-----------------------Full Width Promo Blocks--------------------*/

.contact__promo{
    background: $blue;
    padding: 50px 0px;
    text-align: center;
    margin-top: 50px;
    clear: both;
}

.contact__promo h2{
    font-family: $bebas;
    font-size: 64px;
    color: $white;
    margin: 0px 0px 25px;
}

.button,
.webform-submit{
    display: inline-block;
    font-family: $ubuntu;
    font-size: 24px;
    padding: 10px 25px;
    transition: all ease 0.3s;
} 

.button-blue-transparent,
.webform-submit{
    color: $blue;
    background: $white-trans;
    border: 0px solid $white;
}

.webform-submit{
    color: $purple;
}

.button-blue-transparent:hover{
    color: $blue;
    background: $white;
    text-decoration: none;
}

.work__view__all{
    width: 33.4%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    background: $purple;
    color: $white;
}

.work__view__all h3{
    margin-top: 0px;
}

.work__promo{
    width: 33.333%;
    float: left;
    position: relative;
    overflow: hidden;
}

.view-id-work_random_work{
    position: relative;
}

.work__random{
    position: absolute;
    top: 0px;
    width: 33.3%;
    height: 100%;
    overflow: hidden;
}

.views-row-1 .work__random{
    left: 0px;
}


.views-row-2 .work__random{
    right: 0px;
}

.work__random-img img{
    width: 100%;
    height: auto;
}

.work__random:hover .work__random-text,
.work__promo:hover .work__random-text{
    top: 0%;    
}

.page-node-323 .contact__promo{
    margin: 0px;
    padding: 73px 47px;
    background: url('/sites/default/files/BAL-WrTF-Header-Services.jpg') no-repeat center center;
    background-size: cover;
}

.services__promo{
    background: url('/sites/default/files/BAL-WrTF-Header-Services.jpg') no-repeat center center;
    background-size: cover;
    padding: 73px 0 74px;
}

/*-----------------------Careers--------------------*/

.view-id-careers a{
    display: block;
    width: 100%;
    padding: 40px;
    font-family: $bebas;
    font-size: 38px;
    color: $white;
    background: $blue;
    border-bottom: 1px solid $white;
}

.view-id-careers .views-row-last a{
    border-bottom: 0px;
}

/*-----------------------Webform--------------------*/

.pane-webform-client-block-386{
    background: $purple;
    padding: 40px;
}

.pane-webform-client-block-386 h2{
    color: $white;
}

.pane-webform-client-block-386 label{
    color: $white;
}

.pane-webform-client-block-386 input,
.pane-webform-client-block-386 textarea{
    border-radius: 0px;
    background: $purple;
    border: 1px solid #fff;
    color: #fff;
    box-shadow: none;
    outline: none;
}

.pane-webform-client-block-386 .webform-submit{
    border-radius: 0px;
    width: 100%;
}



/*-----------------------Footer--------------------*/

.footer{
    background: $darkGrey;
    color: #fff;
    position: relative;
}

.footer ul{
    padding: 0px;
    list-style: none;
}

.footer h2{
    font-family: $bebas;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
    border-top: 1px solid $white;
    padding-top: 10px;
    margin-right: 25px;
}

.footer .social a{
    display: block;
    float: left;
    color: #ffffff;
    margin: 0 10px 0px 0px;
    font-size: 18px;
}

.footer__copyright{
    margin: 50px 0px;
    height: 20px;
    font-size: 14px;
}

.copyright{
    float: left;
    margin-right: 40px;
}

.sitemap{
    float: left;
}

footer.section{
	height: auto;
	margin:0px;
	bottom: 0px;
	z-index: 2;
    box-shadow: 1px 1px 15px $black inset;
    position: relative;

}

.front .footer{
	margin-top: 0px;
    padding-bottom: 36px;
    border-top: 0px;
    height: 388px;
}

.front footer.section{
    z-index: 1;
}

.not-front .footer{
	margin-top: 0px;
    border-top: 0px;
}

.front .footer_text{
	position: fixed;
    bottom: 0;
    width: 100%;
}

.not-front .footer_text{
    bottom: 0px;
    width: 100%;
    z-index: 0;
}

.footer a{
    font-size: 14px;
}

.footer a:hover{
    color: #fff;
}

.footer li{
    padding-bottom: 0px;
}